import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import NewsStrip from "../../components/news-strip"

class NewsPage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Tetley's launch new website" description="Tetley's Coaches launch new website including customer portal to improve the booking process for customers." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div>
              <h1 className="page-content-heading">Tetley's launch new website</h1>
              <p className="news-article-date">December 2019</p>
            </div>

            <div className="news-article-body">
              <p>Tetley's coaches are proud to be launching our brand new website. With lots of key new features and sections and an updated theme to compliment.</p>
              <p>An exciting new feature is the customer portal accessible in the footer of the website. Customers can now log in to manage bookings, request quotations and make online card payments.</p>
              <p>"We wanted to simplify the booking process for our customers. The quotation feature on our website now links directly into the booking system. Making the whole process smoother saving time and cutting out the unnecessary administration burden on both customers and office staff alike. With a fresh new look and lots of great features we’re confident the website will be well received" said Operations Manager, Daniel Milnes.</p>
              <p>"Moving into 2020 we have now been in business for over 75 years. We have invested significantly in our fleet to ensure we can continue with our high level of service. We wanted to upgrade our back-office systems which brings with it deeper integration and automation. Modernising our entire operation to the benefit of our customers"</p>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default NewsPage
